<template>
  <div style="width: 100%">
    <!--     第一栏-->
    <div class="div_a" style="">
      <div style="width: 34%; padding: 75px 0 36px 0">
        <img
          :src="black"
          class="div_a_a"
          style="width: 496px"
          @load="loadImage"
        />
      </div>
      <div style="display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;">
        <div class="top_title" style="">
        <img :src="mobile_xiazai_button" class="div_a_c"  />
        <p style="margin-left: 22px">天乙日历</p>
      </div>
      <div style="background: #EF2B37;border-radius: 30px; padding: 20px 40px;text-align: center;width: 100%;font-family: PingFang SC, PingFang SC;
font-weight: 400;
font-size: 26px;
color: #FFFFFF;margin-top: 20px;" @click="goHome(11)">
        立即下载
      </div>
      </div>
      
    </div>
    <div v-show="show" class="content">
      <!--    第二栏-->
      <div
        class="div_b"
        style="width: 100%; justify-content: center; align-items: center"
      >
        <div style="width: 19%; margin-right: 9%">
          <div class="header">
            <p>万年历查询</p>
            <img
              :src="icon"
              style="width: 78px; height: 78px; margin-left: 33px"
            />
          </div>
          <div class="title">
            随时随地，轻松查询万年历，<br />了解历史和未来的日期信息，<br />方便安排生活与工作，<br />让每一天都不遗漏。
          </div>
        </div>
        <div style="position: relative">
          <img
            :src="imageUrl_3"
            style="width: 358px; position: relative; z-index: 3"
          />
          <img
            :src="dy"
            style="
              width: 475px;
              position: absolute;
              bottom: -10px;
              right: -155px;
              z-index: 1;
            "
          />
        </div>
      </div>
      <!-- 第3栏 -->
      <div class="div_b div_t" style="">
        <div style="position: relative; margin-right: 9%">
          <img
            :src="imageUrl_8"
            style="width: 358px; position: relative; z-index: 3"
          />
          <img
            :src="dy"
            style="
              width: 475px;
              position: absolute;
              bottom: -28px;
              left: -90px;
              z-index: 1;
            "
          />
        </div>
        <div>
          <div class="header">
            <p>个人历查询</p>
            <img :src="icon1" style="width: 66px; margin-left: 27px" />
          </div>
          <div class="title">
            根据您的出生日期，生成专属个人历，<br />
            助您把握每一个重要时刻，<br />
            洞悉人生轨迹，提升生活质量。
          </div>
        </div>
      </div>
      <!-- 第四栏 -->
      <div
        class="div_b"
        style="width: 100%; justify-content: center; align-items: center"
      >
        <div style="margin-right: 9%">
          <div class="header">
            <p>老黄历查询</p>
            <img :src="icon2" style="width: 74px; margin-left: 33px" />
          </div>
          <div class="title">
            传承古老智慧，随时查询老黄历，<br />
            获取节日、吉日、忌日等重要信息，<br />
            为您的决策提供指导，让生活更加顺畅。
          </div>
        </div>
        <div style="position: relative">
          <img
            :src="imageUrl_4"
            style="width: 358px; position: relative; z-index: 3"
          />
          <img
            :src="dy"
            style="
              width: 475px;
              position: absolute;
              bottom: -45px;
              right: -100px;
              z-index: 1;
            "
          />
        </div>
      </div>
      <!-- 第5栏 -->
      <div class="div_b div_t">
        <div style="position: relative; margin-right: 9%">
          <img
            :src="imageUrl_5"
            style="width: 358px; position: relative; z-index: 3"
          />
          <img
            :src="dy"
            style="
              width: 475px;
              position: absolute;
              bottom: -28px;
              left: -90px;
              z-index: 1;
            "
          />
        </div>
        <div>
          <div class="header">
            <p>循时养生</p>
            <img :src="icon3" style="width: 91px; margin-left: 27px" />
          </div>
          <div class="title">
            根据时间变化，提供科学养生建议，<br />
            让您在不同的时节都能保持最佳状态，<br />
            享受健康生活。
          </div>
        </div>
      </div>
      <!-- 第六栏 -->
      <div
        class="div_b"
        style="width: 100%; justify-content: center; align-items: center"
      >
        <div style="margin-right: 9%">
          <div class="header">
            <p>九宫飞星</p>
            <img :src="icon4" style="width: 85px; margin-left: 33px" />
          </div>
          <div class="title">
            运用九宫飞星理论，<br />
            分析空间布局与流年运势，<br />
            帮助您优化居住和工作环境，<br />
            提升运势，助力生活每一天。
          </div>
        </div>
        <div style="position: relative">
          <img
            :src="imageUrl_6"
            style="width: 358px; position: relative; z-index: 3"
          />
          <img
            :src="dy"
            style="
              width: 475px;
              position: absolute;
              bottom: -45px;
              right: -100px;
              z-index: 1;
            "
          />
        </div>
      </div>
      <!-- 第7栏 -->
      <div class="div_b div_t">
        <div style="position: relative; margin-right: 9%">
          <img
            :src="imageUrl_7"
            style="width: 358px; position: relative; z-index: 3"
          />
          <img
            :src="dy"
            style="
              width: 475px;
              position: absolute;
              bottom: -28px;
              left: -90px;
              z-index: 1;
            "
          />
        </div>
        <div>
          <div class="header" style="">
            <p>日程提醒</p>
            <img :src="icon5" style="width: 96px; margin-left: 27px" />
          </div>
          <div class="title">
            智能日程提醒，轻松管理您的生活<br />
            与工作安排。无论是重要的会议、<br />
            生日还是节日，都能及时提醒，让<br />
            您不错过每一个重要时刻。
          </div>
        </div>
      </div>
    </div>
    <!--    第13栏-->
    <div
      class="footer"
      style="
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
      "
    >
      <img
        :src="ba"
        style="
          width: 100%;
          height: 100%;
          position: absolute;
          z-index: 1;
          opacity: 0.3;
        "
      />
      <div style="width: 74%; z-index: 2">
        <div style="width: 100%; float: left; padding-top: 41px">
          <div style="display: flex; margin-bottom: 31px">
            <img :src="icon_1" width="5%" />
            <div
              style="
                display: flex;
                justify-content: center;
                align-items: center;
                font-family: PingFang SC, PingFang SC;
                font-weight: bold;
                font-size: 22px;
                color: #000000;
                margin-left: 5px;
              "
            >
              天乙日历
            </div>
          </div>
          <div style="width: 100%">
            <div
              style="
                width: 65%;
                margin-bottom: 0.12rem;
                font-family: PingFang SC, PingFang SC;
                color: #000000;
                display: inline-block;
                font-size: 0.192rem;
              "
            >
              © Copyright by 2024-{{ endTime }} itywnl.com
            </div>
            <div
              style="
                width: 35%;
                margin-bottom: 0.12rem;
                font-family: PingFang SC, PingFang SC;
                color: #000000;
                display: inline-block;
                font-size: 0.192rem;
              "
            >
              邮箱：{{ name }}.com
            </div>
          </div>

          <div style="width: 100%; margin-bottom: 0.32rem">
            <div
              style="
                width: 65%;
                margin-bottom: 0.22rem;
                font-family: PingFang SC, PingFang SC;
                color: #000000;
                display: inline-block;
                font-size: 0.192rem;
              "
            >
              版权所有 重庆易喆云网络科技有限公司
            </div>
            <div
              style="
                width: 35%;
                margin-bottom: 0.22rem;
                font-family: PingFang SC, PingFang SC;
                color: #000000;
                display: inline-block;
                font-size: 0.192rem;
              "
            >
              地址：重庆市丰都县三合街道平都大道西段274号7楼708室547号
            </div>
          </div>
          <div style="width: 100%">
            <div
              style="
                display: inline-block;
                text-decoration-line: underline;
                color: #000000;
                font-size: 0.192rem;
                cursor: pointer;
              "
            >
              <span @click="goHome(4)">用户服务协议</span>
            </div>
            <div
              style="
                display: inline-block;
                text-decoration-line: underline;
                color: #000000;
                margin-left: 1rem;
                font-size: 0.192rem;
                cursor: pointer;
              "
            >
              <span @click="goHome(5)">隐私保护政策</span>
            </div>
          </div>
          <div style="text-align: center; margin: 0.864rem 0rem 0.32rem 0rem">
            <span
              style="font-size: 0.192rem; cursor: pointer"
              @click="goHome(9)"
              >{{ leftName }}</span
            >
            <img :src="icon_2" style="margin: 0rem 0.32rem; width: 0.24rem" />
            <span
              style="font-size: 0.192rem; cursor: pointer"
              @click="goHome(10, { recordcode: recordcode })"
              >{{ rightName }}</span
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import mixin from "@/mixins/comment";
import moment from "moment";

export default {
  mixins: [mixin],
  name: "App",
  components: {},
  data() {
    return {
      dy: require("@/assets/div_1/dy.png"),
      icon: require("@/assets/div_1/search.png"),
      icon1: require("@/assets/div_1/man.png"),
      icon2: require("@/assets/div_1/book.png"),
      icon3: require("@/assets/div_1/time.png"),
      icon4: require("@/assets/div_1/fly.png"),
      icon5: require("@/assets/div_1/ld.png"),
      endTime: "",
      show: false,
      // imageUrl: require("@/assets/div_1/background.png"),
      // imageUrl_2: require("@/assets/div_1/background_2.png"),
      imageUrl_3: require("@/assets/div_1/background_3.png"),
      imageUrl_4: require("@/assets/div_1/background_4.png"),
      imageUrl_5: require("@/assets/div_1/background_5.png"),
      imageUrl_6: require("@/assets/div_1/background_6.png"),
      imageUrl_7: require("@/assets/div_1/background_7.png"),
      imageUrl_8: require("@/assets/div_1/background_8.png"),

      icon_1: require("@/assets/div_1/logo.png"),
      icon_2: require("@/assets/div_1/icon_2.png"),
      black: require("@/assets/div_1/tianyiback.png"),
      // wz: require("@/assets/div_1/wz.png"),
      ba: require("@/assets/div_1/footer.png"),
      mobile_xiazai_button: require("@/assets/div_1/button.png"),
    };
  },
  mounted() {
    this.endTime = moment().format("YYYY");
  },
  methods: {
    loadImage() {
      this.show = true;
    },
  },
};
</script>

<style scoped>
* {
  padding: 0;
  margin: 0; /* 100/31 */
}

body {
  padding: 0;
  margin: 0; /* 100/31 */
}

html {
  padding: 0;
  margin: 0; /* 100/31 */
}

html {
  width: 100%;
}

.content {
  background: #f9fbff;
  /* background-image: url("@/assets/div_1/content.png"); */
  /* background-size: 100% 100%; */
  /* background-repeat: no-repeat; */
}
.div_a {
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: center;
  background-image: url("@/assets/div_1/background.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  .div_a_a {
    /* display: inline-block */
  }

  .div_a_b {
    display: inline-block;
    position: absolute;
    top: 15%;
    left: 25%;
  }

  .div_a_c {
    /* position: absolute; */
    height: 120px;
    width: 120px;
    cursor: pointer;
    /* top: 74%;
    left: 50%;
    transform: translate(-50%, 0); */
  }
}

.div_b {
  position: relative;
  display: flex;
  flex-direction: row;
  .div_b_a {
    display: inline-block;
    width: 100%;
  }

  .div_b_b {
    display: flex;

    .div_b_b_a {
      /* width: 20%; */
      display: flex;
      /* height: 2.5rem; */
      width: 0.7rem;
      text-align: center;
      padding-top: 50px;
      /* height: 40px; */
    }

    .div_b_b_b {
      width: 80%;
      display: flex;
      justify-content: center;

      .div_b_b_b_a {
        text-transform: none;
        height: 100%;
        box-sizing: border-box;

        .div_b_b_b_a_a {
          font-size: 0.288rem;
          font-family: PingFang SC, PingFang SC;
          font-weight: 400;
          line-height: 0.512rem;
        }

        .div_b_b_b_a_b {
          color: #aeaeae;
          font-weight: 400;
          font-size: 0.192rem;
          line-height: 0.288rem;
          margin-top: 0.3rem;
        }
      }
    }
  }
}

.div_c {
  display: flex;
  justify-content: center;
  align-items: center;
}
.footer {
  /* background-image: url("@/assets/div_1/footer.png");
  background-size: 100% 100%;
  background-repeat: no-repeat; */
  /* background-color: #f1f1f1; */
}
.top_title {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-left: -35px;
  font-family: 146-SSQingYaSongTi, 146-SSQingYaSongTi;
  font-weight: normal;
  font-size: 39px;
  color: #121d42;
}
.div_t {
  width: 100%;
  justify-content: center;
  align-items: center;
  background-image: url("@/assets/div_1/bg1.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
}
.header {
  display: flex;
  flex-direction: row;
  align-items: center;
  font-family: REEJI-HaoHengGB-Flash-Black, REEJI-HaoHengGB-Flash-Black;
  font-weight: bold;
  font-size: 39px;
  color: #121d42;
}
.title {
  font-family: DengXian-Bold, DengXian-Bold;
  font-weight: normal;
  font-size: 27px;
  color: #121d42;
  margin-top: 25px;
}
</style>
